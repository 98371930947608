<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="1200px"
    k
    top="3vh"
    :modal="false"
    :title="model.id?$l('project.edit','编辑项目'):$l('project.add','新增项目')"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('project.labelWidth','80px')">
      <el-tag class="tag">项目信息</el-tag>
      <div class="vm-separate form">
        <el-form-item :label="$l('project.name','项目名称')" prop="name">
          <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
        </el-form-item>
      </div>

      <el-tag class="tag">电梯信息</el-tag> <el-button size="small" type="text" @click="$refs.elevatorSelect.open()">选择电梯</el-button>
      <el-table
        :data="model.items"
        label-position="left"
        :row-class-name="rouClassNameFn"
        class="demo-table-expand"
      >
        <el-table-column prop="elevatorName" label="内部编号" align="center" width="150"></el-table-column>
        <el-table-column prop="realEstateName" label="楼盘名称" align="center"></el-table-column>
        <el-table-column prop="buildingName" label="楼宇名称" align="center"></el-table-column>
        <el-table-column prop="brandName" label="品牌名称" align="center" width="150"></el-table-column>

        <el-table-column prop="modelName" label="型号名称" align="center" width="150"></el-table-column>
        <el-table-column prop="regCode" label="注册代码" align="center" width="150"></el-table-column>

        <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="100">
          <template slot-scope="scope">
            <el-button type="danger" @click="deleteLine(scope.row)">{{$l("common.delete", "删除")}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="handleSubmit">{{$l("common.submit", "确定")}}</el-button>
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
    </span>
    <user-select ref="userSelectWb" @select="selectUserWb"></user-select>
    <user-select ref="projectUserSelectWb" @select="projectUserSelect"></user-select>
    <customer-select ref="customerSelect" @select="selectCustomer"></customer-select>
    <elevator-select ref="elevatorSelect" :checkbox="true" @select="elevatorSelect"></elevator-select>
    <building-select ref="buildingSelect" @select="buildingSelect"></building-select>
  </el-dialog>
</template>
<script>
  import UserSelect from "@/views/user/UserSelect";
  import CustomerSelect from "@/views/elevatorUsingCompany/CompanySelect";
  import ElevatorSelect from "@/common/select/ElevatorSelect";
  import BuildingSelect from "@/common/select/RealEstateSelect";
  export default {
    components: { UserSelect, CustomerSelect,ElevatorSelect,BuildingSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          name: "",
          customerCompanyId: "",
          customerCompanyName: "",
          wbManagerId: "",
          wbManagerName: "",
          status: "",
          items:[],
        },
        filter:{},
        options: [{
          value: "regulation",
          label: "按规保养",
        }, {
          value: "as_need",
          label: "按需保养",
        }],
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`project/maintenance-project/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = {items:[],...data};
            if (!this.model.items) {
              this.model.items=[];
            }
          });
        }
      },
      selectCustomer(row) {
        this.model.customerCompanyId = row.id;
        this.model.customerCompanyName = row.name;
      },
      selectUserWb(row,index) {

        this.$set(this.model.maintenanceProjectItemVos[index],"assigneeId",row.id);
        this.$set(this.model.maintenanceProjectItemVos[index],"assigneeName",row.name);
      },
      elevatorSelect(row) {
        if (row instanceof Array) {
          row.forEach(item=>{
            if (!this.model.items.some(v=>v.id===item.id)) {
              this.model.items.push(({...item,elevatorId:item.id}));
            }
          });
        }else {
          if (this.model.items.some(v=>v.id===row.id)) {
            this.$message.info("重复添加");
          }else {
            this.model.items.push({...row,elevatorId:row.id});
          }
        }

      },

      buildingSelect(row) {
        this.$set(this.model,"realEstateId",row.id);
        this.$set(this.model,"realEstateName",row.name);
        this.$set(this.model,"usingCompanyName",row.usingCompanyName);
        this.$set(this.model,"developerCompanyName",row.developerCompanyName);

      },
      projectUserSelect(row) {
        this.$set(this.model,"assigneeId",row.id);
        this.$set(this.model,"assigneeName",row.name);
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("project/maintenance-project", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      addLine() {
        this.model.maintenanceProjectItemVos.push({
          key: this.$util.genKey(),
          "regCode": 0,
          "elevatorId": 0,
          "elevatorMaintenanceId": 0,
          "captainId":0,
          captainName:"",
        });
      },
      deleteLine(row) {
        this.model.items=this.model.items.filter(v=>v.id!==row.id);
      },
      rouClassNameFn({row,rowIndex}) {
        row.index=rowIndex;
      },
      submit() {
        console.log(this.model);
      },
    },
  };
</script>
<style lang="scss" scoped>
.tag{
  font-size: 20px;
  margin: 5px 0;
  text-align: center;
  color: black;
  font-weight: bolder;
  border: none;
  background-color: white;
}
.content{
  margin: 5px 0 5px 15px;
}
.form{
  margin: 5px 0;
}
</style>
