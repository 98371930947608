<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('project.edit','编辑项目'):$l('project.add','合并项目')"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('project.labelWidth','80px')">
      <el-form-item :label="$l('project.name','项目名称')" prop="name" :rules="$rule.notNull">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="客户" prop="customerCompanyId" :rules="$rule.notNull">
        <vm-select-input :value="model.customerCompanyName" @select="$refs.customerSelect.open()"></vm-select-input>
      </el-form-item>
      <el-form-item label="省市区" prop="districtCode" :rules="$rule.notNull">
        <vm-district :area-code.sync="model.districtCode"></vm-district>
      </el-form-item>
      <el-form-item label="维保类型" prop="type">
        <el-select v-model="model.type" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="维保开始时间" prop="startTime">
        <el-date-picker
          v-model="model.startTime"
          value-format="yyyy-MM-dd hh:mm:ss"
          type="date"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="维保结束时间" prop="endTime">
        <el-date-picker
          v-model="model.endTime"
          value-format="yyyy-MM-dd hh:mm:ss"
          type="date"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="签署时间" prop="signedTime">
        <el-date-picker
          v-model="model.signedTime"
          value-format="yyyy-MM-dd hh:mm:ss"
          type="date"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="付款时间" prop="paidTime">
        <el-date-picker
          v-model="model.paidTime"
          value-format="yyyy-MM-dd hh:mm:ss"
          type="date"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
      <el-button v-if="!disabled" type="primary" @click="addLine">新增</el-button>
      <el-button v-if="!disabled" type="primary" @click="deleteLine">删除</el-button>
      <ul>
        <li v-for="(item,index) in model.item" :key="index">
          <el-form-item label="电梯信息" prop="elevatorId">
            <vm-select-input :value="item.elevatorCode" @select="$refs.elevatorSelect.open(index)"></vm-select-input>
          </el-form-item>
          <el-form-item label="维保队长" prop="captainId">
            <vm-select-input :value="item.captainName" @select="$refs.userSelectWb.open(index)"></vm-select-input>
          </el-form-item>

          <el-divider></el-divider>
        </li>
      </ul>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <user-select ref="userSelectWb" @select="selectUserWb"></user-select>
    <customer-select ref="customerSelect" @select="selectCustomer"></customer-select>
    <elevator-select ref="elevatorSelect" @select="elevatorSelect"></elevator-select>
  </el-dialog>
</template>
<script>
  import UserSelect from "@/views/user/UserSelect";
  import CustomerSelect from "@/views/elevatorUsingCompany/CompanySelect";
  import VmDistrict from "@/components/VmDistrictCascader";
  import ElevatorSelect from "@/views/elevator/ElevatorSelect";
  export default {
    components: {UserSelect, CustomerSelect,VmDistrict,ElevatorSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          name: "",
          customerCompanyId: "",
          customerCompanyName: "",
          wbManagerId: "",
          wbManagerName: "",
          status: "",
          item:[],
          projectIds:[],
        },
        options: [{
          value: "regulation",
          label: "按规保养",
        }, {
          value: "as_need",
          label: "按需保养",
        }],
      };
    },
    methods: {
      open(selectList) {
        const arr = [];
        selectList.forEach(item=>{
          arr.push(item.id);
        });
        this.model.projectIds=arr;
        this.dialogVisible = true;
        // this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/project/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = {item:[],...data};
          });
        }
      },
      selectCustomer(row) {
        this.model.customerCompanyId = row.id;
        this.model.customerCompanyName = row.name;
      },
      selectUserWb(row,index) {
        this.$set(this.model.item[index],"captainId",row.id);
        this.$set(this.model.item[index],"captainName",row.name);
      },
      elevatorSelect(row,index) {
        this.$set(this.model.item[index],"elevatorId",row.id);
        this.$set(this.model.item[index],"elevatorCode",row.elevatorCode);
        this.$set(this.model.item[index],"regCode",row.regCode);
        this.$set(this.model.item[index],"elevatorMaintenanceId",row.elevatorMaintenanceId);

      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .post("/maintenance/project/merge", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      addLine() {
        this.model.item.push({
          key: this.$util.genKey(),
          "regCode": 0,
          "elevatorId": 0,
          "elevatorMaintenanceId": 0,
          "captainId":0,
          captainName:"",
        });
      },
      deleteLine() {
        this.model.item.pop();
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>